import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import styled from 'styled-components';

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import HexagramsDisplay from './HexagramsDisplay';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSwipeable } from 'react-swipeable';
import Switch from '@mui/material/Switch'; 
import Snackbar from '@mui/material/Snackbar';


const getUrlParameter = (name) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getUrlParameterPerm = (name) => {
  // First, try to get the parameter from the URL
  let value = getUrlParameter(name);
  
  // If the parameter exists and it's not an empty string, store it in localStorage
  if (value) {
    localStorage.setItem(name, value);
  } else {
    // If the parameter doesn't exist in the URL, try to get it from localStorage
    value = localStorage.getItem(name);
  }

  return value;
};

const isLiteMode  = getUrlParameterPerm('m') === 'lite';
const isWhiteMode = getUrlParameterPerm('t') === 'w';


const Box = styled.div`
  width: 120px;
  height: 20px;
  background: ${props => props.type === '1' ? '#5F4B32' : 'linear-gradient(90deg,  #5F4B32 35%, #0000 35%, #0000 65%, #5F4B32 65%)'};
`;

const Circle = styled.div`
  width: ${(props) => props.isBigger ? '10px' : '8px'};
  height: ${(props) => props.isBigger ? '10px' : '8px'};
  border-radius: 50%;
  background-color: #5F4B32;
  position: absolute;
  right: ${(props) => props.isBigger ? '-15px' : '-14.5px'};
  top: 50%;
  transform: translateY(-50%);
`;


const BoxWithCircle = styled.div`
  display: flex;
  justify-content: center; /* Center the box horizontally */
  position: relative; /* Establish a positioning context */
  height: 20px; /* Match height of Box */
  margin-bottom: 5px; /* Space between rows */
`;

const BoxContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  height: 100%; // Adjust as needed
`;

const BoxAndKeyTextContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  transition: transform 0.3s ease;
  padding:0px 20px;
  max-width: 420px
`;

const TransitionContainer = styled.div`
  transition: transform 0.3s ease;
`;

const ListContainer = styled.div`
  
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  height: 100vh;
`;

const SwipeableDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  max-width: 420px; /* Set your desired max width */
  margin: 10px auto 0px;
  text-align: center;
  overflow-y: auto;
  max-height: 60svh;
  min-height: 150px;
  padding: 10px 20px 10px 20px;
`;

const PageChipContainer = styled.div`
  display: flex;
  justify-content: flex-start; // Distributes children evenly
  align-items: center; // Aligns children vertically in the center
  flex-wrap: wrap; // Allows items to wrap to next line
  margin: 2px; // Counteracts the child margins for alignment

  > * { // Applies styles to every direct child
    margin: 2px !important; // Margin for each chip
  }
`;

const DrawerDivider = styled(Divider)`
  padding: 10px 0px;
`;

const ChipLarge = styled(Chip)`
  margin: 5px 5px !important;
`;

const BlackboardOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 2vh 0;
  cursor: pointer;
  writing-mode: vertical-rl;
  text-orientation: mixed;
`;

const BlackboardText = styled.div`
  font-size: clamp(4vh, calc(80vh / ${({ length }) => length}), 4vh); // Dynamically adjust font size
  text-align: left;
  overflow: hidden; // Prevent overflow if the text is too long
  max-width: 100%; // Ensure it stays within the viewport width
  max-height: 80vh; // Limit the text container height
  white-space: normal; // Allow text to wrap onto multiple lines
  line-height: 1.2; // Adjust line height for readability
  word-break: break-word; // Ensure long words break properly
`;

function App() {

  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false); // Keep this for main content loading
  const [hexagramData, setHexagramData] = useState(null);
  const [hexagramLoading, setHexagramLoading] = useState(false); // New state for hexagram data loading

  const [error, setError] = useState('');

  const [showAllKeyContent, setShowAllKeyContent] = useState(false);
  const [showDetailedContent, setShowDetailedContent] = useState(false);

  const toggleShowAllKeyContent = () => setShowAllKeyContent(!showAllKeyContent);
  const toggleShowDetailedContent = () => setShowDetailedContent(!showDetailedContent);

  const [drawerState, setDrawerState] = useState({ top: false, left: false, bottom: false, right: false });
  const [contentView, setContentView] = useState('LSIS'); // Possible values: 'LSIS', 'IJ'

  const [translateValue, setTranslateValue] = useState(0);

  const [currentHexagramNumber, setCurrentHexagramNumber] = useState(1);
  const totalHexagrams = 64;

  const [navigationLoading, setNavigationLoading] = useState(false);
  const [sequencePosition, setSequencePosition] = useState(0);

  const [annotatedText, setAnnotatedText] = useState(null);
  const lastSelectionRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const [combinedDictData, setCombinedDictData] = useState(null);
  const [loadingDictData, setLoadingDictData] = useState(false);
  const [dictDataError, setDictDataError] = useState('');

  const [isDictDrawerOpen, setIsDictDrawerOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [showAllContent, setShowAllContent] = useState(false); // New state to control content visibility

  const [isSongMode, setIsSongMode] = useState(true); // Step 1

  const [dictSelectedVariation, setDictSelectedVariation] = useState(0);


  const boxAndKeyTextRef = useRef(null);
  const boxRef = useRef(null);
  const drawerContainerRef = useRef(null); // Ref for the SwipeableDrawerContainer

  const isBg = drawerState["bottom"]; // Determine if background should be applied

  const handleShowAllContent = () => {
    console.log("handleShowAllContent")
    setShowAllContent(true);
  };

  const getDrawerHeight = () => {
    if (apiData) {
      return '60svh'; // Set height for apiData case
    } else if (hexagramData) {
      return '80svh'; // Set height for hexagramData case
    }
    return '60svh'; // Default height
  };

  function generateBoxes(input, circleLines, biggerCircleIndex) {
    // Split the input into an array
    const numbers = input.split('');

    // Generate a Box component for each number
    const boxComponents = numbers.map((num, index) => (
      <BoxWithCircle key={index}>
        <Box type={num} />
        {circleLines.includes(index + 1) && (
          <Circle isBigger={(index + 1) === biggerCircleIndex} />
        )}
      </BoxWithCircle>
    ));

    return <BoxContainer ref={boxRef}>
        {boxComponents}
      </BoxContainer>
    ;
  }

  const toggleSongMode = () => { // Step 2
    setIsSongMode(prevMode => !prevMode);
  };


  useEffect(() => {
    if(!isWhiteMode){
    document.body.style.backgroundColor = "#FBF0D9";
    document.body.style.color = "#5F4B32"; // Also corrected the color code
    }
  }, []);

  useEffect(() => {
    fetchCombinedDictData(annotatedText);
  }, [annotatedText]);

  useEffect(() => {
    if (isBg && boxRef.current && boxAndKeyTextRef.current && drawerContainerRef.current) {
      const viewportHeight = window.innerHeight;
      const drawerHeight = drawerContainerRef.current.getBoundingClientRect().height;

      const availableSpaceAboveDrawer = viewportHeight - drawerHeight;

      const boxHeight = availableSpaceAboveDrawer>boxAndKeyTextRef.current.clientHeight?boxAndKeyTextRef.current.clientHeight:boxRef.current.clientHeight

      const boxTop = boxAndKeyTextRef.current.getBoundingClientRect().top;

      const translateY = - boxTop + (availableSpaceAboveDrawer - boxHeight)/2

      setTranslateValue(translateY); // Apply negative value for upward movement
    } else {
      setTranslateValue(0);
    }
  }, [isBg]);


  const fetchData = async () => {
    setLoading(true);
    setError('');
    setHexagramData(null);

    try {
      // Create a promise that resolves after 2 seconds
      const delay = new Promise(resolve => setTimeout(resolve, 2000));

      // Fetch request
      const fetchPromise = fetch('https://cards.iching.workers.dev/api/spreads?deck=i')
        .then(response => {
          if (!response.ok) throw new Error('Network response was not ok');
          return response.json();
        });

      // Wait for both the fetch request and the 2-second delay to be complete
      const [data] = await Promise.all([fetchPromise, delay]);

      setApiData(data);
      if (data && data.key) {
        setHexagramData(data.key); // Use the key to set the initial hexagram data
        setCurrentHexagramNumber(data.key.n); // Assuming .n holds the hexagram number

        // Initialize sequencePosition
        setSequencePosition(0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };


  // State to control the blackboard overlay visibility and content
  const [blackboardText, setBlackboardText] = useState('');
  const [isBlackboardVisible, setIsBlackboardVisible] = useState(false);

  // Function to handle the button click and show the blackboard overlay
  const handleBlackboardTextClick = (text) => {
    setBlackboardText(text);
    setIsBlackboardVisible(true);
  };

  // Function to close the blackboard overlay
  const handleBlackboardClick = () => {
    setIsBlackboardVisible(false);
  };

  const [selectedRange, setSelectedRange] = useState(null);
  let selectionInterval = null;

  const getSelectedRange = () => {
    try {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const currentRange = selection.getRangeAt(0).toString();
        if (currentRange.trim() !== '') {
          const trimmedRange = currentRange.length > 4 ? currentRange.substring(0, 4) : currentRange;
          if (trimmedRange !== lastSelectionRef.current) {
            lastSelectionRef.current = trimmedRange;

            // Clear any existing debounce timeout
            if (debounceTimeoutRef.current) {
              clearTimeout(debounceTimeoutRef.current);
            }

            // Set a new debounce timeout
            debounceTimeoutRef.current = setTimeout(() => {
              setAnnotatedText(trimmedRange); // Update only after 1000ms delay
              setIsSnackbarOpen(true);
            }, 200);
          }
        }
      }
    } catch (err) {
      console.error('Error getting selected range:', err);
    }
  };

  useEffect(() => {
    const selectionInterval = setInterval(getSelectedRange, 150);

    return () => {
      clearInterval(selectionInterval);
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleTextClick = () => {

  };

  const handleClick = () => {
    fetchData();
  };

  const renderMainData = (data) => {
    return data.heteronyms.map((heteronym, index) => (
      <div key={index}>
        <p>Bopomofo: {heteronym.bopomofo}</p>
        <p>Pinyin: {heteronym.pinyin}</p>
        {heteronym.definitions.map((definition, defIndex) => (
          <div key={defIndex}>
            <div>Type: {definition.type || 'N/A'}</div>
            <div>Definition: {definition.def}</div>
            {definition.example && (
              <ul>
                {definition.example.map((example, exIndex) => (
                  <li key={exIndex}>{example}</li>
                ))}
              </ul>
            )}
            {definition.quote && (
              <ul>
                {definition.quote.map((quote, qIndex) => (
                  <li key={qIndex}>{quote}</li>
                ))}
              </ul>
            )}
            {definition.synonyms && (
              <p>Synonyms: {definition.synonyms}</p>
            )}
            {/* Include other fields here if necessary */}
          </div>
        ))}
      </div>
    ));
  };

  const fetchCombinedDictData = async (word) => {
    if (!word) {
      setCombinedDictData(null);
      return;
    }

    setLoadingDictData(true);
    setDictDataError('');

    try {
      const url = `https://www.moedict.tw/pua/${encodeURIComponent(word)}.json`;
      const tranUrl = `https://www.moedict.tw/a/${encodeURIComponent(word)}.json`;

      const [response1, response2] = await Promise.all([
        fetch(url),
        fetch(tranUrl)
      ]);

      if (!response1.ok || !response2.ok) throw new Error('Failed to fetch data');

      const [data1, data2] = await Promise.all([
        response1.json(),
        response2.json()
      ]);

      setCombinedDictData({ mainData: data1, translation: data2.translation });
    } catch (error) {
      console.error('Error fetching combined dictionary data:', error);
      setDictDataError('Failed to fetch combined dictionary data');
    } finally {
      setLoadingDictData(false);
    }
  };

  const fetchHexagramData = async (n) => {
    try {
      const response = await fetch(`https://cards.iching.workers.dev/api/i/hexagrams/${n}`);
      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();
    } catch (error) {
      console.error('Error fetching hexagram data:', error);
      throw error; // Propagate the error for handling it outside this function
    }
  };

  const handleHexagramsDisplayClick = async (n) => {
    try {
      setHexagramLoading(true); // Start loading
      setDrawerState({ ...drawerState, bottom: true }); // Open the drawer
      const data = await fetchHexagramData(n);
      setHexagramData(data);
      setCurrentHexagramNumber(n)
    } catch (error) {
      setError('Failed to fetch hexagram data');
    } finally {
      setHexagramLoading(false); // End loading
    }
  };

const getFilteredHexagramSequence = () => {
  // Determine the starting point based on apiData.key
  const isOriginStart = apiData && apiData.key && apiData.key.n === (apiData.origin && apiData.origin.n);
  let sequence = isOriginStart
    ? [apiData.origin, apiData.changed, apiData.alt, apiData.pair, apiData.within]
    : [apiData.changed, apiData.origin, apiData.alt, apiData.pair, apiData.within];

  // Filter out null values
  sequence = sequence.filter(hex => hex !== null);
  
  return sequence;
};

const getHexagramRemark = () => {
  const isOriginStart = apiData && apiData.key && apiData.key.n === (apiData.origin && apiData.origin.n);
  let sequence = isOriginStart
    ? ['本', '之', '錯', '綜', '互']
    : ['之', '本', '錯', '綜', '互'];

  const name = sequence[sequencePosition];
  if (isOriginStart && name === '本') {
    return ''; // Skip showing the remark for '本' when isOriginStart is true
  }
  return name;
};


const getNextHexagramNumber = () => {
  if (!apiData) {
    return currentHexagramNumber === totalHexagrams ? 1 : parseInt(currentHexagramNumber) + 1;
  }

  const sequence = getFilteredHexagramSequence();
  const nextPosition = (parseInt(currentHexagramNumber) + 1) % sequence.length;
  setSequencePosition(nextPosition); // Update the sequence position
  return sequence[nextPosition].n;
};

const getPreviousHexagramNumber = () => {
  if (!apiData) {
    return currentHexagramNumber === 1 ? totalHexagrams : parseInt(currentHexagramNumber) - 1;
  }

  const sequence = getFilteredHexagramSequence();
  const prevPosition = sequencePosition === 0 ? sequence.length - 1 : parseInt(currentHexagramNumber) - 1;
  setSequencePosition(prevPosition); // Update the sequence position
  return sequence[prevPosition].n;
};

const getNextApiData = () => {
  const sequence = getFilteredHexagramSequence();
  const currentIndex = sequence.findIndex(hex => hex.n === currentHexagramNumber);
  const nextIndex = (currentIndex + 1) % sequence.length;
  return sequence[nextIndex];
};

const getPreviousApiData = () => {
  const sequence = getFilteredHexagramSequence();
  const currentIndex = sequence.findIndex(hex => hex.n === currentHexagramNumber);
  const prevIndex = currentIndex === 0 ? sequence.length - 1 : currentIndex - 1;
  return sequence[prevIndex];
};

const onNextClick = async () => {
  const nextNumber = getNextHexagramNumber();
  setNavigationLoading(true);
  setShowAllContent(false);

  try {
    let data;
    if (apiData) {
      data = getNextApiData(nextNumber);
    }

    if (drawerContainerRef.current) {
      drawerContainerRef.current.scrollTop = 0; // Scroll to top
    }    

    if (!data) {
      // Fetch the data if it's not present in apiData
      data = await fetchHexagramData(nextNumber);
    }

    setHexagramData(data);
    setCurrentHexagramNumber(nextNumber);

    setDictSelectedVariation(0)

  } catch (error) {
    setError('Failed to fetch next hexagram data');
  } finally {
    setNavigationLoading(false);
  }
};

const onPreviousClick = async () => {
  const prevNumber = getPreviousHexagramNumber(); // Assuming this function is implemented
  setNavigationLoading(true);
  setShowAllContent(false);

  try {
    let data;

    if (apiData) {
      data = getPreviousApiData(prevNumber);
    }

    if (drawerContainerRef.current) {
      drawerContainerRef.current.scrollTop = 0; // Scroll to top
    }    

    if (!data) {
      // Fetch the data if it's not present in apiData
      data = await fetchHexagramData(prevNumber);
    }

    setHexagramData(data);
    setCurrentHexagramNumber(prevNumber);


  } catch (error) {
    setError('Failed to fetch previous hexagram data');
  } finally {
    setNavigationLoading(false);
  }
};

  let content;

  const resetToInitialState = () => {
    setApiData(null);
    setError('');
    setLoading(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };


  function isHighlighted(key, index = null) {

    if(sequencePosition != 0 && sequencePosition != 1){
      return false
    }

    if(apiData.keyMsg.pos[0] === key &&  !apiData.keyMsg.pos[1]){
      return true
    }else if(apiData.keyMsg.pos[0] === key && apiData.keyMsg.pos[1]){
      return  apiData.keyMsg.pos[1].includes(index+1)
    }

  }

  const handleChipClick = (ref) => {
    // Check if the device is an iOS device
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isDict = ref.d;

    // Define the URL based on the device type
    const url = (isIOS && !isDict) ? `pdfefile:///iching/${ref.f}.pdf#${ref.p}` : ref.u;

    // Open the URL
    window.open(url, '_blank').focus();
  };

  const swipeConfig = {
    delta: { left: 60, right: 40 } ,
    swipeDuration: 200,
  }

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: (e) => onNextClick(),
    onSwipedRight: (e) => onPreviousClick(),
    ...swipeConfig,
  });


  const handleDictLookupOpen = () => {
    window.getSelection().removeAllRanges()
    setIsSnackbarOpen(false);
    setIsDictDrawerOpen(true);
  };

  const handleDictLookupClose = () => {
    setIsDictDrawerOpen(false);
  };

  const handleLookupSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const dictLookupAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleDictLookupOpen}>
        查字典
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleLookupSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const renderCombinedDictData = () => {
    // Always display the annotated text at the top
    const annotatedTextHeader = <h3 style={{ margin: '0 auto' }}>{annotatedText}</h3>;

    if (loadingDictData) {
      return (
        <div>
          {annotatedTextHeader}
          <p>Loading...</p>
        </div>
      );
    }

    if (dictDataError) {
      return (
        <div>
          {annotatedTextHeader}
          <p>Error: {dictDataError}</p>
        </div>
      );
    }

    if (!combinedDictData) {
      return (
        <div>
          {annotatedTextHeader}
          <p>No data found</p>
        </div>
      );
    }

    const hasMainData = combinedDictData.mainData && combinedDictData.mainData.heteronyms;
    const hasTranslations = combinedDictData.translation && combinedDictData.translation.English;
    const handleCUHKLinkClick = () => {
      const cuhkURL = `https://humanum.arts.cuhk.edu.hk/Lexis/lexi-mf/search.php?word=${encodeURIComponent(annotatedText)}`;
      window.open(cuhkURL, '_blank');
    };

    return (
      <div>
        {annotatedTextHeader}
        <div>
          {hasMainData ? (
            <div>
              <DrawerDivider>字典</DrawerDivider>
              <p>
                {renderMainData(combinedDictData.mainData)}
              </p>
            </div>
          ) : <p>No main data available</p>}

          {hasTranslations ? (
            <div>
              <DrawerDivider>英文</DrawerDivider>
              {combinedDictData.translation.English.map((tran, index) => (
                <p key={index}>{tran}</p>
              ))}
            </div>
          ) : <p>No translations available</p>}

          <DrawerDivider>參考</DrawerDivider>
          <PageChipContainer>
            <ChipLarge 
              label="CUHK" 
              size="small" 
              variant="outlined" 
              onClick={handleCUHKLinkClick} 
            />
          </PageChipContainer>
          <div style={{ margin: '20px auto' }} ></div>
        </div>
      </div>
    );
  };


  const handleVariationClick = (idx) => {
    console.log(idx)
    setDictSelectedVariation(idx);
  };

  const renderCombinedContent = (data) => {
    // Check if data is available
    if (!data) return null;

    // Determine which data type is being used
    const isApiData = data.key ? true : false;

    // Access the appropriate data fields based on the data type
    const contentData = isApiData ? data.key : data;
    const { c, content, refs, dict, title_variation } = contentData;

    const boxes = generateBoxes(contentData.l,[], []);

    const findPinyinAndWord = (character) => {
      const entry = content.p[character];
      if (entry && entry.p) {
        return `${character}<sub style="margin:0 2px; font-size: 8px">${entry.p}${entry.w[0]}</sub>`;
      }
      return character;
    };

    // Modify renderCombinedContent function to process text with findPinyinAndWord
    const processTextWithPinyin = (text) => {
      if(!content.p){
        return text;
      }
      return text.split('').map(findPinyinAndWord).join('');
    };

    const renderChip = (word, idx) => {
      // Add any additional code or logic here
      return (
        <ChipLarge 
          key={idx}
          label={`${word.d} ${word.t}`} 
          size="large" 
          variant="outlined" 
          onClick={() => handleChipClick(word)} 
        />
      );
    };

    const getContentChars = (idx, arr) => {
      
      const conditionMet = arr[idx]?.type === 'string' && arr[idx]?.content;

      if (conditionMet) {
        const content = arr[idx].content.join('');
        
        const uniqueChars = [...new Set(content)];
        
        return uniqueChars;
      } else {
        return false;
      }
    };

    const handleDictCharClick = (char, dict) => {
      const url = dict.u.replace('{w}', encodeURIComponent(char));
      console.log(`Clicked char: ${char}, URL: ${url}`);
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.focus();
    };


    return (
      <>
        <div {...swipeableHandlers}>

          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: '0 20px'
          }}>
            <IconButton onClick={onPreviousClick} disabled={navigationLoading}>
              <ArrowBackIosNewTwoToneIcon />
            </IconButton>

            {navigationLoading ? (
              <CircularProgress size={24} style={{ margin: '0 auto' }} />
            ) : (
              <h3 style={{ margin: '0 auto' }}>
                {currentHexagramNumber}. {c}
                {apiData && getHexagramRemark() && (
                  <sub style={{ margin: '0 2px', fontWeight: 'normal', fontSize: 'smaller' }}>
                    {getHexagramRemark()}
                  </sub>
                )}
              </h3>
            )}

            <IconButton onClick={onNextClick} disabled={navigationLoading}>
              <ArrowForwardIosTwoToneIcon />
            </IconButton>
          </div>


          {!isLiteMode || showAllContent ?
          <>
            <DrawerDivider>卦辭</DrawerDivider>
            <p className={`${apiData && isHighlighted('c') ? 'highlighted' : ''}`}>
              <span dangerouslySetInnerHTML={{ __html: processTextWithPinyin(content.c) }}></span>
            </p>

            <DrawerDivider>彖傳</DrawerDivider>
            <p>
              <span dangerouslySetInnerHTML={{ __html: processTextWithPinyin(content.j) }}></span>
            </p>

            <DrawerDivider>大象</DrawerDivider>
            <p>
              <span dangerouslySetInnerHTML={{ __html: processTextWithPinyin(content.i) }}></span>
            </p>

            <DrawerDivider>爻辭 <sub>小象</sub></DrawerDivider>
            {content.ls.map((lsItem, idx) => {
              // Split the lsItem text at the first occurrence of ":"
              const parts = lsItem.split(/：(.*)/);
              const beforeColon = parts[0];
              const afterColon = parts[1];

              return (
                <div className="lsIsContainer" key={idx}>
                  <span className={`lsItem ${apiData && isHighlighted('ls', idx) ? 'highlighted' : ''}`}>
                    <span
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleBlackboardTextClick(lsItem)}  // Replace with your click handler
                    >
                      {beforeColon}
                    </span>
                    {afterColon && (
                      <>
                        <span>: </span>
                        <span dangerouslySetInnerHTML={{ __html: processTextWithPinyin(afterColon) }}></span>
                      </>
                    )}
                  </span>
                  <span className="isItem">{content.is[idx]}</span>
                </div>
              );
            })}

            {content.w?
            <><DrawerDivider>文言</DrawerDivider>
              <p>{content.w}</p></>:<></>}

            <DrawerDivider>序卦</DrawerDivider>
            <div className="lsIsContainer">
              {content.o.map((oItem, idx) => (
                <span key={idx} className={`oItem ${!oItem.m ? 'isItem' : ''}`}>
                    {oItem.original || oItem.text}
                </span>
              ))}
            </div>

            <DrawerDivider>雜卦</DrawerDivider>
            <div className="lsIsContainer">
              {content.m.map((mItem, idx) => (
                <>
                  <span key={idx} className="mItemOriginal">{mItem.original}</span>
                  <span className="isItem">{mItem.explanation}</span>
                </>
              ))}
            </div>

            <>
              <DrawerDivider>版本</DrawerDivider>
              <div style={{ display: 'grid', gridTemplateColumns: `repeat(${title_variation.length}, 1fr)`, gap: '0px' }}>
                {title_variation.map((varianion, idx) => (
                  <div key={idx} onClick={() => handleVariationClick(idx)} style={{ cursor: 'pointer' }}>
                    <div style={{ fontSize: 8 }}>{varianion.book}</div>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: 5, display: 'grid', gridTemplateColumns: `repeat(${title_variation.length}, 1fr)`, gap: '0px' }}>
                {title_variation.map((varianion, idx) => (
                  <div key={idx} onClick={() => handleVariationClick(idx)} style={{ cursor: 'pointer' }}>
                    <span>
                      {varianion.content == null ? "--" : (varianion.type === "image" ?
                       <img style={{ zoom: 1.0, padding:2, background:"#fff" }} src={`${varianion.content}`} alt="Image" /> :
                        varianion.content.map((line, index) => <React.Fragment key={index}>{line}<br /></React.Fragment>))}
                    </span>
                  </div>
                ))}
              </div>
            </>


            <DrawerDivider>甲骨/小篆</DrawerDivider>
            <div style={{ display: 'flex', width: '100%' }}>
              {/* 甲骨 Section */}
              <div style={{ width: '50%', padding: '10px', borderRight: `1px solid ${drawerTheme.palette.divider}`, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              {getContentChars(dictSelectedVariation, title_variation) && document.fonts.check('16px JiaGuWen-font', title_variation[dictSelectedVariation].content)
                ? (
                        <div className='jiaGuWen-font' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                      {title_variation[dictSelectedVariation].content}
                    </div>
                  )
                  : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                      --
                    </div>
                  )
                }
              </div>
              
              {/* 小篆 Section */}
              <div style={{ width: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {getContentChars(dictSelectedVariation, title_variation) 
                  ? (
                    <div className='shuoWen-font' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                      {title_variation[dictSelectedVariation].content}
                    </div>
                  )
                  : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                      --
                    </div>
                  )
                }
              </div>
            </div>

            <DrawerDivider>字源 ({title_variation[dictSelectedVariation].book})</DrawerDivider>
            <PageChipContainer>
              {getContentChars(dictSelectedVariation, title_variation) 
                ? getContentChars(dictSelectedVariation, title_variation).sort().flatMap((char) =>
                    dict.map((word, idx) => (
                      <ChipLarge 
                        key={`${word.d}-${char}-${idx}`} 
                        label={`${word.d} ${char}`} 
                        size="large" 
                        variant="outlined" 
                        onClick={() => handleDictCharClick(char, word)} 
                      />
                    ))
                  )
                : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' , width:"100%"}}> -- </div>
              }
            </PageChipContainer>

          </>:<>
             <div style={{fontSize: 'smaller' , margin:'12px 6px', cursor:"pointer", textDecoration: "underline"
              }} onClick={handleShowAllContent}>顯示內容</div>
          </>
          }

          <DrawerDivider>參考</DrawerDivider>
          <PageChipContainer>
            {refs.map((ref, idx) => (
              <ChipLarge label={`${ref.t} P${ref.toc}`} size="large" variant="outlined" onClick={()=>{handleChipClick(ref)}} />
            ))}
          </PageChipContainer>

          <div style={{ margin: '20px auto' }} >
          </div>
        </div>
      </>
    );

  };

  if (loading) {
    content = <CenteredContainer>
                <CircularProgress />
              </CenteredContainer>
    ;
  } else if (apiData) {
    const boxes = generateBoxes(apiData.key.l,apiData.changes, apiData.keyMsg.pos[1]?apiData.keyMsg.pos[1][0]:null);
    content = (
      <CenteredContainer>
        <TransitionContainer style={{ transform: `translateY(${translateValue}px)` }}>
          <BoxAndKeyTextContainer
            ref={boxAndKeyTextRef}
            isBg={isBg}
          >
            {boxes}

            <h3 style={{ cursor: 'pointer' }} onClick={(e) => {
              if (e.target.innerText === '--') {
                e.target.innerHTML = `
                  <span style="font-weight: ${apiData.origin.c === apiData.key.c ? 'bold' : 'normal'};">
                    ${apiData.origin.c}
                  </span>
                  ${apiData.changed && apiData.changed.c ? `
                    <span style="font-weight: normal;">之</span>
                    <span style="font-weight: ${apiData.changed.c === apiData.key.c ? 'bold' : 'normal'};">
                      ${apiData.changed.c}
                    </span>
                  ` : ''}
                `;
              }
            }}>
              --
            </h3>

            {
              apiData.keyMsg.content.map((item, index) => 
                index === 0 ? <h4 key={index}>{item}</h4> : <h5 key={index}>{item}</h5>
              )
            }
          </BoxAndKeyTextContainer>
          <div>
            <Divider>
              <Button  onClick={toggleDrawer("bottom", true)} variant="text" size="small">
                Show All
              </Button>
            </Divider>
          </div>

          <div>
            <Button
              sx={{margin:"5px",display:"none"}}
              component="a" 
              href={`https://api.whatsapp.com/send?phone=+910000000000&text=Data: ${apiData.l}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
            >
            Contact on WhatsApp
            </Button>
          </div>
          <div>
            <Button sx={{margin:"5px"}} onClick={resetToInitialState} variant="outlined">Try Again</Button>
          </div>
        </TransitionContainer>
      </CenteredContainer>
    );

  } else if (error) {
    content = (
      <div>
        <div style={{ color: 'red' }}>{error}</div>
        <Button onClick={resetToInitialState} variant="outlined">Retry</Button>
      </div>
    );
  } else {

    content = (
      <>
      <div>
        <ListContainer>
          <HexagramsDisplay onClick={handleHexagramsDisplayClick} isLiteMode={isLiteMode} isSongMode={isSongMode}/>
        </ListContainer>
        <Switch // Step 3
          checked={isSongMode}
          onChange={toggleSongMode}
          name="songModeSwitch"
          inputProps={{ 'aria-label': 'toggle song mode' }}
        />
        <div style={{margin:"20px"}}>
          <Button onClick={handleClick} variant="outlined" >Go</Button>
        </div>
      </div>
      </>
    );
  }
  
  const theme = createTheme(isWhiteMode?{}:{
    palette: {
      primary: {
        main: "#5F4B32", // primary color for buttons and such
        contrastText: "#FBF0D9" // text on primary color, for good contrast
      },
      background: {
        default: "#FBF0D9" // sepia background
      },
      text: {
        primary: "#5F4B32", // primary text color, sepia tone
        // add secondary or disabled if needed for your design
      }
    }
  });

  const drawerTheme = createTheme(!isLiteMode?{}:{
    palette: {
      mode: 'dark', // Set the theme mode to dark
      // Define other colors as needed
      background: {
        default: '#121212', // Dark background color
        paper: '#1e1e1e', // Background color for elements like cards and dialogs
      },
      text: {
        primary: '#ffffff', // Primary text color
        secondary: 'rgba(255, 255, 255, 0.7)', // Secondary text color
      },
      // You can also customize other colors like primary, secondary, error, etc.
    },
    // Add other theme customizations as needed
  });

  return (
  
    <div className="App" onClick={handleTextClick}>

      <ThemeProvider theme={theme}>
        {content}
      </ThemeProvider>

      <ThemeProvider theme={drawerTheme}>
        <SwipeableDrawer 

          anchor={"bottom"}
          open={drawerState["bottom"]}
          onClose={() => {
            setDrawerState({ ...drawerState, bottom: false })
            setIsSnackbarOpen(false)
          }}
          onOpen={() => setDrawerState({ ...drawerState, bottom: true })}
        >
          <SwipeableDrawerContainer
            ref={drawerContainerRef}
            style={{ maxHeight: getDrawerHeight() }} // Dynamically set the max height
          >
            {hexagramLoading && <CircularProgress />} {/* Loading indicator for hexagram data */}
            {hexagramData && !hexagramLoading ? renderCombinedContent(hexagramData) : null}
          </SwipeableDrawerContainer>
        </SwipeableDrawer>
      </ThemeProvider>

      <ThemeProvider theme={drawerTheme}>
        <SwipeableDrawer
          anchor="bottom"
          open={isDictDrawerOpen}
          onClose={handleDictLookupClose}
          onOpen={() => setIsDictDrawerOpen(true)}
        >
          <SwipeableDrawerContainer>
            {renderCombinedDictData()}
          </SwipeableDrawerContainer>
        </SwipeableDrawer>
      </ThemeProvider>

      <Snackbar
        open={annotatedText && isSnackbarOpen}
        message={annotatedText}
        action={dictLookupAction}
        autoHideDuration={5000}
        onClose={handleLookupSnackbarClose}
      />

{isBlackboardVisible && (
  <BlackboardOverlay onClick={handleBlackboardClick}>
    <BlackboardText length={blackboardText.length}>
      {blackboardText}
    </BlackboardText>
  </BlackboardOverlay>
)}

    </div>

  
  );
}

export default App;